<template>
  <div class="pl-4">
    <b-row class="mt-4">
      <b-col md="2">
        <FormSelect
          label="Mes Referencia"
          :selectOptions="optionsMonths"
          v-model="selectedMonth"
          :ready="true"
          @selectChanged="check()"
        />
      </b-col>
      <b-col md="2">
        <FormSelect
          label="Ano Referencia"
          :selectOptions="optionsYears"
          v-model="selectedYear"
          :ready="true"
          @selectChanged="check()"
        />
      </b-col>
      <b-col md="2"
        ><FormSelect
          label="Tipo"
          :selectOptions="optionsType"
          :ready="true"
          v-model="selectedType"
        />
      </b-col>
      <b-col
        ><FormInput
          :label="`Digite o nome, email ou contrato`"
          :ready="true"
          v-model="search"
        />
      </b-col>
      <div class="d-flex align-items-end">
        <b-button @click="check()" variant="primary">Buscar</b-button>
      </div>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-card no-body class="d-flex flex-row p-2">
          <div
            class="d-flex align-items-center"
            style="font-size: 50px; height: 100px"
          >
            <fa-icon class="p-0 text-info" icon="file-pdf"></fa-icon>
          </div>
          <div class="d-flex flex-column pl-3 justify-content-center">
            <strong class="text-info" style="font-size: 36px">{{
              counts.files
            }}</strong>
            <p class="text-muted">
              Faturas em {{ lote.split("_")[0] }} {{ lote.split("_")[1] }}
            </p>
          </div>
        </b-card>
      </b-col>
      <b-col>
        <b-card no-body class="d-flex flex-row p-2 h-100">
          <div
            class="d-flex align-items-center"
            style="font-size: 50px; height: 100px"
          >
            <fa-icon
              class="p-0 text-info"
              icon="paper-plane"
              style="font-size: 50px"
            ></fa-icon>
          </div>
          <div class="d-flex flex-column pl-3 justify-content-center">
            <strong class="text-info" style="font-size: 36px">{{
              counts.sent
            }}</strong>
            <p class="text-muted">
              Enviados em {{ lote.split("_")[0] }} {{ lote.split("_")[1] }}
            </p>
          </div>
        </b-card>
      </b-col>
      <b-col>
        <b-card no-body class="d-flex flex-row p-2 h-100">
          <div
            class="d-flex align-items-center"
            style="font-size: 50px; height: 100px"
          >
            <fa-icon
              class="p-0 text-info"
              icon="exclamation-circle"
              style="font-size: 50px"
            ></fa-icon>
          </div>
          <div class="d-flex flex-column pl-3 justify-content-center">
            <strong class="text-info" style="font-size: 36px">{{
              counts.errors
            }}</strong>
            <p class="text-muted">
              Erros em {{ lote.split("_")[0] }} {{ lote.split("_")[1] }}
            </p>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <Sent
      ref="sent"
      v-if="selectedType == 'sent'"
      :lote="lote"
      :search="{ type: selectedFind, value: search }"
    />
    <Error
      ref="error"
      v-if="selectedType == 'error'"
      :lote="lote"
      :search="{ type: selectedFind, value: search }"
    />
    <File
      ref="file"
      v-if="selectedType == 'file'"
      :lote="lote"
      :search="{ type: selectedFind, value: search }"
    />
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      selectedType: "sent",
      selectedMonth: "",
      selectedYear: "",
      selectedFind: "",
      formLabel: "",
      search: "",
      counts: { files: 0, errors: 0, sent: 0 },
    };
  },
  computed: {
    lote() {
      const filtered = this.optionsMonths.filter((i) => {
        return i.value == this.selectedMonth;
      });
      return `${filtered[0].text}_${this.selectedYear}`;
    },
    optionsMonths() {
      const months = [
        "JANEIRO",
        "FEVEREIRO",
        "MARÇO",
        "ABRIL",
        "MAIO",
        "JUNHO",
        "JULHO",
        "AGOSTO",
        "SETEMBRO",
        "OUTUBRO",
        "NOVEMBRO",
        "DEZEMBRO",
      ];
      return months.map((m, idx) => {
        return { text: m, value: idx };
      });
    },
    optionsYears() {
      return [
        { text: "2023", value: "2023" },
        { text: "2024", value: "2024" },
      ];
    },
    optionsType() {
      return [
        { text: "Enviados", value: "sent" },
        { text: "Erros", value: "error" },
        { text: "Lista do mes", value: "file" },
      ];
    },
    optionsFind() {
      return [
        { text: "Sem Filtro", value: "" },
        { text: "Nome", value: "cliente" },
        { text: "Contrato", value: "contrato" },
        { text: "Email", value: "accepted" },
      ];
    },
    optionsFindById() {
      return _.keyBy(this.optionsFind, "value");
    },
  },
  methods: {    
    check() {
      this.get_count();
      if (this.selectedType == "sent") {
        this.$refs.sent.get_sent_list(this.search);
      }
      if (this.selectedType == "error") {
        this.$refs.error.get_sent_list(this.search);
      }
      if (this.selectedType == "file") {
        this.$refs.file.get_sent_list(this.search);
      }
    },
    async get_count() {
      const response = await this.$store.dispatch("http_post_sender", {
        url: "/boletos/get_counts",
        data: { lote: this.lote },
      });
      const norm = {
        files: response.data.payload.files.count,
        errors: response.data.payload.errors.count,
        sent: response.data.payload.sent.count,
      };
      this.counts = norm;
    },
    async get_sent() {
      const response = await this.$store.dispatch("http_get_sender", {
        url: `/boletos/sent?lote=${filtered[0].text}_${this.selectedYear}`,
      });
      console.log(response);
    },
  },
  async mounted() {
    this.selectedMonth = moment().month();
    this.selectedYear = moment().year();
    this.get_count();
  },
  components: {
    Sent: () => import("./components/Sent.vue"),
    Error: () => import("./components/Error.vue"),
    File: () => import("./components/File.vue"),
  },
};
</script>

<style></style>
